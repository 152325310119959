import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import ui from "../../../../util/ui";
import { validateForm } from "../../../../util/validation";

import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import HiddenInput from "../../../atoms/hidden-input";

class CustomProductCreate extends React.Component {

  static defaultProps = {
    customProductType: null,
    onCancel: () => {},
    onSave: () => {}
  };

  constructor(props) {

    super(props);

    this.form = React.createRef();
  }

  validateForm(form, data) {

    validateForm(form, data, {
      price: ['required'],
      custom_product_type: ['required'],
    });
  }

  onSubmit(customProductData) {

    http.post(API_HOST+API_ROOT+'custom-products', customProductData, true
    ).then(response => {
      if (response.success) {
        this.props.onSave(response.result);
      }
    }).catch(error => {
      if (error.message === 'duplicate_custom_product') {
        ui.notify('A custom product with that id already exists', 'error');
      }
    });
  }

  render() {

    return (
      <Form
        ref={this.form}
        validate={(form, data) => this.validateForm(form, data)}
        onSubmit={(data) => this.onSubmit(data)}
        showSubmitButton={false}
      >
        <TextInput name="price" label="Price" showRequiredIndicator={true}/>
        <TextInput name="description" label="Description" />
        <HiddenInput name="custom_product_type" value={this.props.customProductType.id} />
      </Form>
    );
  }
}

export default CustomProductCreate;