import './index.scss';
import './list-item.scss';

import connect from "react-redux/es/connect/connect";
import { updateList } from "../../../state/modules/resources";

import React from 'react';
import modifiers from "../../../util/components";
import time from "../../../util/time";

import Pagination from "../pagination";
import Loader from "../../atoms/loader";
import IconButton from "../../atoms/icon-button";

class List extends React.Component
{
  static defaultProps = {
    id: null,
    model: null,
    showPagination: false,
    showHeader: false,
    limit: null,
    search: null,
    filters: {},
    sort: {},
    selectable: true,
    submittable: false,
    onItemClick: () => {},
    onItemDoubleClick: () => {},
    onSubmit: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      isMounted: false,
      activeItemId: this.props.activeItemId || null,
      selectedItemId: this.props.activeItemId || null,
    };
  }

  componentDidMount() {
    this.updateList({
      model: this.props.model,
      limit: this.props.limit,
      items: [],
      search: this.props.search,
      filters: this.props.filters,
      sort: this.props.sort,
      pagination: {
        currentPage: 1
      }
    }).then(() => {
      this.setState({isMounted: true});
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.selectable && (! prevState.activeItemId && this.state.activeItemId) || this.props.activeItemId) {

      document.addEventListener('keydown', this.handleOnNext);
      document.addEventListener('keydown', this.handleOnPrevious);
      document.addEventListener('keydown', this.handleOnClose);

      if (this.props.submittable) {
        document.addEventListener('keydown', this.handleOnSubmit);
      }
    }
  }

  componentWillUnmount() {

    document.removeEventListener('keydown', this.handleOnNext);
    document.removeEventListener('keydown', this.handleOnPrevious);
    document.removeEventListener('keydown', this.handleOnClose);
    document.removeEventListener('keydown', this.handleOnSubmit);
  }

  handleOnSubmit = (e) => {

    if (e.key === "Enter") {

      e.preventDefault();
      e.stopPropagation();

      const items = this.props.lists[this.props.id].items;

      this.props.onSubmit(this.getActiveItem(items));
    }
  };

  handleOnClose = (e) => {

    if (e.key === "Escape") {

      e.preventDefault();
      e.stopPropagation();

      if (this.state.activeItemId) {
        this.setState({activeItemId: null});
      }
    }
  };

  handleOnNext = (e) => {

    if (e.key === "ArrowRight" && this.state.activeItemId) {

      e.preventDefault();
      e.stopPropagation();

      let items = this.props.lists[this.props.id].items;

      this.handleItemClick(items[Math.min(this.getActiveIndex(items) + 1, items.length-1)]);
    }
  };

  handleOnPrevious = (e) => {

    if (e.key === "ArrowLeft" && this.state.activeItemId) {

      e.preventDefault();
      e.stopPropagation();

      let items = this.props.lists[this.props.id].items;

      this.handleItemClick(items[Math.max(0, this.getActiveIndex(items) - 1)]);
    }
  };

  getActiveIndex(items) {
    return items.findIndex(item => item.id === this.state.activeItemId);
  }

  getActiveItem(items) {
    return items.find(item => item.id === this.state.activeItemId);
  }

  updateList(opts) {

    return this.props.updateList(this.props.id, opts);
  }

  handleItemClick(item) {

    this.setState({
      activeItemId: item.id
    });

    this.props.onItemClick(item);
  }
  
  handleItemDoubleClick(item) {

    this.setState({
      selectedItemId: item.id
    });

    this.props.onItemDoubleClick(item);
  }

  getListItem(component, item, index) {

    let TagName = component.type;

    return (
      <div key={index} className={'list__item'+(this.props.selectable && this.state.activeItemId === item.id ? ' list__item--selected' : '')} onClick={this.handleItemClick.bind(this, item)} onDoubleClick={this.handleItemDoubleClick.bind(this, item)}>
        <TagName {...component.props} model={item} selected={this.state.selectedItemId === item.id} />
      </div>
    );
  }

  getPagination() {

    let list = this.props.lists[this.props.id];

    if (! list) {
      return null;
    }

    let { items } = list;

    if (items.length) {
      return (this.props.showPagination ? <Pagination listId={this.props.id} model={this.props.model}/> : null);
    }
  }

  getHeader() {

    if (! this.props.header) {
      return;
    }

    return (
        <div className="list__header">
          Latest update: {time.nowHourAndMinutes()}
          <IconButton iconType={'refresh'} onClick={e => {this.updateList()}} />
        </div>
    );
  }

  getContent() {
    let list = this.props.lists[this.props.id];

    if (! this.state.isMounted || list.isLoading) {
      return <Loader size={'large'} />;
    }

    let { items } = list;

    if (! items.length) {
      return (
        <div className="list__placeholder">
          No items found for these criteria
        </div>
      );
    }

    let itemComponents = items.map((item, index) => {
      if (this.props.children.length) {
        return this.props.children.map(component => this.getListItem(component, item, index));
      }
      return this.getListItem(this.props.children, item, index);
    });

    return (
      <div className="list__items">
        {this.props.header}
        {itemComponents}
      </div>
    );
  }

  render() {

    return (
      <div className={'list'+modifiers('list', this.props.modifiers)+(this.props.selectable ? ' list--selectable' : '')}>
        {this.getHeader()}
        {this.getContent()}
        {this.getPagination()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lists: state.resources.lists,
});

const mapDispatchToProps = {
  updateList: updateList
};

export default connect(mapStateToProps, mapDispatchToProps)(List);